var map;

window.addMarkers = function addMarkers() {
  var element = document.querySelector("#transactions-list");
  var transactions = window.transactions = JSON.parse(element.dataset.transactions);

  map.removeMarkers();

  transactions.forEach(function(transaction) {
    if (transaction.latitude && transaction.longitude) {
      var marker = map.addMarker({
        lat: transaction.latitude,
        lng: transaction.longitude,
        title: transaction.address,
        infoWindow: {
          content: `<h6>${transaction.venue_name}</h6><p>${transaction.venue_street_address}<br/>${transaction.venue_city}</p>`
        }
      });
    }
  });

  setSafeBounds(element);
}

function setSafeBounds(element) {
  var l = element.dataset.l;
  if (l) {
    var latlngs   = l.split(',');
    var southWest = new google.maps.LatLng(latlngs[0], latlngs[1]);
    var northEast = new google.maps.LatLng(latlngs[2], latlngs[3]);
    var bounds    = new google.maps.LatLngBounds(southWest, northEast);
    map.fitBounds(bounds, 0);

  } else {
    map.fitZoom();
  }
}

document.addEventListener("turbolinks:load", () => {

  if (!document.querySelector("#map")) { return false }

  map = window.map = new GMaps({
    div: '#map',
    lat: 38.5816,
    lng: -121.4944,
    maxZoom: 18,
    disableDefaultUI: true,
    scaleControl: true,
    zoomControl: true
  });

  addMarkers();

  map.addListener("dragend", function() {
    var bounds = map.getBounds();
    var location = bounds.getSouthWest().toUrlValue() + "," + bounds.getNorthEast().toUrlValue();
    Turbolinks.visit(`/practices?l=${location}`);
  });
});