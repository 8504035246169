/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()

import "bootstrap"
import "../stylesheets/application"
document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
})

require("trix")
require("@rails/actiontext")

require("jquery-ui/ui/widgets/sortable")
document.addEventListener("turbolinks:load", () => {
  $(".sortable").sortable({
    update: function(e, ui) {
      $.ajax({
        method: "PATCH",
        url: $(this).data("url"),
        data: $(this).sortable('serialize')
      });
    }
  });


})

require('./maps')

import flatpickr from "flatpickr"
require("flatpickr/dist/flatpickr.css")

document.addEventListener("turbolinks:load", () => {
  flatpickr("[data-behavior='flatpickr'][data-format='datetime']", {
    enableTime: true,
    dateFormat: "Y-m-d H:i",
    time_24hr: true
  });
  flatpickr("[data-behavior='flatpickr'][data-format='date']", {
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d"
  });
})



